import { GetStaticProps } from 'next'

import { Sanity } from '@cellulargoods/types'
import { REVALIDATE_TIME } from '@cellulargoods/core'
import { GENERIC_DOCUMENT, fetchDocument } from '@cellulargoods/services'

import { WebCMSRenderer } from 'components/Renderers/WebCMSRenderer'
import { SiteWebLayout } from 'components/Site/SiteWebLayout'

interface IndexProps extends Sanity.GetLayoutDataPayload {
  document: Sanity.Homepage
}

const Index = ({ navbar, document, footer, siteSettings }: IndexProps) => {
  const { CMSComponents, PageSettings } = document
  const { transparentHeader, theme } = PageSettings ?? {}

  return (
    <SiteWebLayout
      navbar={navbar}
      footer={footer}
      siteSettings={siteSettings}
      transparentHeader={Boolean(transparentHeader)}
      headerTheme={theme}
      seoProps={{
        pageMeta: {
          ...siteSettings.PageMeta,
          ...document.PageMeta,
          slug: '/',
        },
      }}
    >
      {CMSComponents && CMSComponents.map((item) => WebCMSRenderer(item))}
    </SiteWebLayout>
  )
}

export const getStaticProps: GetStaticProps = async ({ preview }) => {
  const sanityResult = await fetchDocument({
    filter: `_type == 'homepage'`,
    preview,
    projection: GENERIC_DOCUMENT,
  })

  return {
    notFound: !sanityResult,
    props: {
      ...sanityResult,
      preview: !!preview,
    },
    revalidate: REVALIDATE_TIME, // seconds
  }
}

export default Index
